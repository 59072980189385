<template>
	<div class="pageview">
		<div class="pageviewtitle">
			<div class="titletab">老师列表</div>
		</div>
		<div class="buttonview">
			<el-button type="primary" @click="isadd = true">添加老师</el-button>
		</div>
		<el-table :data="list" style="width: 100%" stripe border v-loading="allloading">
			<el-table-column show-overflow-tooltip type="index" align="center" width="50" />
			<el-table-column show-overflow-tooltip align="center" prop="username" label="名称" />
			<el-table-column show-overflow-tooltip label='性别' align="center">
				<template #default="scope">
					<label v-if="scope.row.gender == 1">男</label>
					<label v-if="scope.row.gender == 0">女</label>
				</template>
			</el-table-column>
			<el-table-column show-overflow-tooltip align="center" prop="identifier" label="教工号" />
			<el-table-column show-overflow-tooltip align="center" prop="schoolName" label="所属学校" />
			<el-table-column show-overflow-tooltip align="center" prop="gradeDto.gradeName" label="年级" />
			<el-table-column show-overflow-tooltip align="center" prop="subjectDto.subjectName" label="学科" />
			<el-table-column show-overflow-tooltip align="center" prop="email" label="邮箱" />
			<el-table-column show-overflow-tooltip align="center" prop="mobile" label="手机号" />
			<el-table-column label='操作' align="center">
				<template #default="scope">
					<!-- <el-button size="small" type="primary" @click="edits(scope.row)">修改
					</el-button> -->
					<el-button size="small" type="danger" @click="deletes(scope.row)">删除
					</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="elpagination">
			<el-pagination background layout="prev, pager, next" @current-change="pagechange" :page-count="pages" />
		</div>
	</div>



	<el-dialog v-model="isadd" title="添加老师" width="30%" top="5vh">
		<div class="row">
			<div class="row-title">姓名<el-tag type="danger" size="small">必填</el-tag>
			</div>
			<el-input v-model="addjson.username" placeholder="请输入姓名" clearable type="text"></el-input>
		</div>
		<div class="row">
			<div class="row-title">性别<el-tag type="danger" size="small">必填</el-tag>
			</div>
			<el-select v-model="addjson.gender" placeholder="请选择性别" style="width: 100%;">
				<el-option label="女" value="0" />
				<el-option label="男" value="1" />
			</el-select>
		</div>
		<div class="row">
			<div class="row-title">手机号<el-tag type="danger" size="small">必填</el-tag>
			</div>
			<el-input v-model="addjson.mobile" placeholder="请输入手机号" clearable type="text"></el-input>
		</div>
		<div class="row">
			<div class="row-title">邮箱
			</div>
			<el-input v-model="addjson.email" placeholder="请输入邮箱" clearable type="text"></el-input>
		</div>
		<div class="row">
			<div class="row-title">身份证号
			</div>
			<el-input v-model="addjson.idCard" placeholder="请输入身份证号" clearable type="text"></el-input>
		</div>
		<div class="row">
			<div class="row-title">教工号<el-tag type="danger" size="small">必填</el-tag>
			</div>
			<el-input v-model="addjson.identifier" placeholder="请输入教工号" clearable type="text"></el-input>
		</div>
		<div class="row">
			<div class="row-title">学科<el-tag type="danger" size="small">必填</el-tag>
			</div>
			<el-select v-model="addjson.subjectId" placeholder="请选择学科" style="width: 100%;">
				<el-option v-for="item in subjectList" :label="item.subjectName" :value="item.id" :key="item.id" />
			</el-select>
		</div>
		<div class="row">
			<div class="row-title">年级<el-tag type="danger" size="small">必填</el-tag>
			</div>
			<el-select v-model="addjson.gradeId" @change="gradechange" placeholder="请选择年级" style="width: 100%;">
				<el-option v-for="item in gradeList" :label="item.gradeName" :value="item.id" :key="item.id" />
			</el-select>
		</div>
		<div class="row">
			<div class="row-title">班级<el-tag type="danger" size="small">必填</el-tag>
			</div>
			<el-select v-model="addjson.classroomIds" placeholder="请选择班级" collapse-tags collapse-tags-tooltip
				style="width: 100%;" multiple>
				<el-option v-for="item in classList" :label="item.classroomName" :value="item.id" :key="item.id" />
			</el-select>
		</div>
		<div class="row">
			<div class="row-title">出版社<el-tag type="danger" size="small">必填</el-tag>
			</div>
			<el-select v-model="addjson.pressId" placeholder="请选择出版社" style="width: 100%;">
				<el-option v-for="item in pressesList" :label="item.pressName" :value="item.id" :key="item.id" />
			</el-select>
		</div>
		<div class="row">
			<div class="row-title">所属学校<el-tag type="danger" size="small">必填</el-tag>
			</div>
			<el-select v-model="addjson.tenantId" placeholder="请选择所属学校" style="width: 100%;">
				<el-option v-for="item in tenantsList" :label="item.tenantName" :value="item.id" :key="item.id" />
			</el-select>
		</div>
		<template #footer>
			<span class="dialog-footer">
				<el-button @click="isadd = false">取消</el-button>
				<el-button type="primary" @click="submitadd" :loading="loading">添加</el-button>
			</span>
		</template>
	</el-dialog>

	<el-dialog v-model="isedit" title="修改老师" width="30%" top="5vh">
		<div class="row">
			<div class="row-title">姓名<el-tag type="danger" size="small">必填</el-tag>
			</div>
			<el-input v-model="editjson.username" placeholder="请输入姓名" clearable type="text"></el-input>
		</div>
		<div class="row">
			<div class="row-title">性别<el-tag type="danger" size="small">必填</el-tag>
			</div>
			<el-select v-model="editjson.gender" placeholder="请选择性别" style="width: 100%;">
				<el-option label="女" value="0" />
				<el-option label="男" value="1" />
			</el-select>
		</div>
		<div class="row">
			<div class="row-title">手机号<el-tag type="danger" size="small">必填</el-tag>
			</div>
			<el-input v-model="editjson.mobile" placeholder="请输入手机号" clearable type="text"></el-input>
		</div>
		<div class="row">
			<div class="row-title">邮箱
			</div>
			<el-input v-model="editjson.email" placeholder="请输入邮箱" clearable type="text"></el-input>
		</div>
		<div class="row">
			<div class="row-title">身份证号
			</div>
			<el-input v-model="editjson.idCard" placeholder="请输入身份证号" clearable type="text"></el-input>
		</div>
		<div class="row">
			<div class="row-title">教工号<el-tag type="danger" size="small">必填</el-tag>
			</div>
			<el-input v-model="editjson.identifier" placeholder="请输入教工号" clearable type="text"></el-input>
		</div>
		<div class="row">
			<div class="row-title">学科<el-tag type="danger" size="small">必填</el-tag>
			</div>
			<el-select v-model="editjson.subjectId" placeholder="请选择学科" style="width: 100%;">
				<el-option v-for="item in subjectList" :label="item.subjectName" :value="item.id" :key="item.id" />
			</el-select>
		</div>
		<div class="row">
			<div class="row-title">年级<el-tag type="danger" size="small">必填</el-tag>
			</div>
			<el-select v-model="editjson.gradeId" @change="gradechange" placeholder="请选择年级" style="width: 100%;">
				<el-option v-for="item in gradeList" :label="item.gradeName" :value="item.id" :key="item.id" />
			</el-select>
		</div>
		<div class="row">
			<div class="row-title">班级<el-tag type="danger" size="small">必填</el-tag>
			</div>
			<el-select v-model="editjson.classroomIds" placeholder="请选择班级" collapse-tags collapse-tags-tooltip
				style="width: 100%;" multiple>
				<el-option v-for="item in classList" :label="item.classroomName" :value="item.id" :key="item.id" />
			</el-select>
		</div>
		<div class="row">
			<div class="row-title">出版社<el-tag type="danger" size="small">必填</el-tag>
			</div>
			<el-select v-model="editjson.pressId" placeholder="请选择出版社" style="width: 100%;">
				<el-option v-for="item in pressesList" :label="item.pressName" :value="item.id" :key="item.id" />
			</el-select>
		</div>
		<div class="row">
			<div class="row-title">所属学校<el-tag type="danger" size="small">必填</el-tag>
			</div>
			<el-select v-model="editjson.tenantId" placeholder="请选择所属学校" style="width: 100%;">
				<el-option v-for="item in tenantsList" :label="item.tenantName" :value="item.id" :key="item.id" />
			</el-select>
		</div>
		<template #footer>
			<span class="dialog-footer">
				<el-button @click="isedit = false">取消</el-button>
				<el-button type="primary" @click="submitedit" :loading="loading">修改</el-button>
			</span>
		</template>
	</el-dialog>
</template>

<script>
	import {
		teacherlist,
		subjectslist,
		gradeslist,
		classroomslist,
		presseslist,
		tenantslist,
		teachercreat,
		usersdelete
	} from "../../util/api"
	export default {
		data() {
			return {
				allloading: false,
				pageNum: 1,
				pageSize: 15,
				pages: 1,
				list: null,
				isadd: false,
				isedit: false,
				addjson: {
					username: null, //姓名
					gender: null, //性别
					mobile: null, //手机号
					email: null, //邮箱
					idCard: null, //身份证号码
					identifier: null, //教工号
					subjectId: null, //学科
					gradeId: null, //年级
					classroomIds: [], //班级
					pressId: null, //出版社
					tenantId: null //所属学校
				},
				editjson: {
					id: null,
					username: null, //姓名
					gender: null, //性别
					mobile: null, //手机号
					email: null, //邮箱
					idCard: null, //身份证号码
					identifier: null, //教工号
					subjectId: null, //学科
					gradeId: null, //年级
					classroomIds: [], //班级
					pressId: null, //出版社
					tenantId: null //所属学校
				},
				loading: false,
				subjectList: null, //学科列表
				gradeList: null, //年级列表
				classList: null, //班级列表
				pressesList: null, //出版社列表
				tenantsList: null //租户列表
			}
		},
		created() {
			this.getlist()
			this.getsubjectslist()
			this.getgradeslist()
			this.getpresseslist()
			this.gettenantslist()
		},
		methods: {
			submitedit() {
				if (!this.editjson.subjectName) {
					this.$message({
						type: 'warning',
						message: '请输入名称',
					})
				} else if (!this.editjson.subjectDesc) {
					this.$message({
						type: 'warning',
						message: '请输入描述',
					})
				} else {
					this.loading = true
					subjectsedit(this.editjson).then((res) => {
						this.loading = false
						this.isedit = false
						this.$message({
							type: 'success',
							message: '修改成功',
						})
						this.getlist()
					}).catch((error) => {
						this.loading = false
					})
				}
			},
			edits(row) {
				this.editjson = {
					id: row.id,
					username: row.username, //姓名
					gender: row.gender, //性别
					mobile: row.mobile, //手机号
					email: row.email, //邮箱
					idCard: row.idCard, //身份证号码
					identifier: row.identifier, //教工号
					subjectId: row.subjectId, //学科
					gradeId: row.gradeId, //年级
					classroomIds: [], //班级
					pressId: row.pressId, //出版社
					tenantId: row.tenantId //所属学校
				}
				this.isedit = true
			},
			deletes(row) {
				this.$confirm('是否删除老师“' + row.username + '”？', '提示', {
					confirmButtonText: '是',
					cancelButtonText: '否',
					type: 'error',
				}).then(() => {
					usersdelete([row.id]).then((res) => {
						if(res.data.code == 200){
							this.$message({
								type: 'success',
								message: '删除成功',
							})
							this.getlist()
						}
					})
				})
			},
			submitadd() {
				if (!this.addjson.username) {
					this.$message({
						type: 'warning',
						message: '请输入名称',
					})
				} else if (!this.addjson.gender) {
					this.$message({
						type: 'warning',
						message: '请选择性别',
					})
				} else if (!this.addjson.mobile) {
					this.$message({
						type: 'warning',
						message: '请输入手机号',
					})
				} else if (!this.addjson.identifier) {
					this.$message({
						type: 'warning',
						message: '请输入教工号',
					})
				} else if (!this.addjson.subjectId) {
					this.$message({
						type: 'warning',
						message: '请选择学科',
					})
				} else if (!this.addjson.gradeId) {
					this.$message({
						type: 'warning',
						message: '请选择年级',
					})
				} else if (this.addjson.classroomIds.length <= 0) {
					this.$message({
						type: 'warning',
						message: '请选择班级',
					})
				} else if (!this.addjson.pressId) {
					this.$message({
						type: 'warning',
						message: '请选择出版社',
					})
				} else if (!this.addjson.tenantId) {
					this.$message({
						type: 'warning',
						message: '请选择所属学校',
					})
				} else {
					this.loading = true
					teachercreat(this.addjson).then((res) => {
						if(res.data.code == 200){
							this.$message({
								type: 'success',
								message: '添加成功'
							})
							this.addjson = {
								username: null, //姓名
								gender: null, //性别
								mobile: null, //手机号
								email: null, //邮箱
								idCard: null, //身份证号码
								identifier: null, //教工号
								subjectId: null, //学科
								gradeId: null, //年级
								classroomIds: [], //班级
								pressId: null, //出版社
								tenantId: null //所属学校
							}
							this.loading = false
							this.isadd = false
							this.getlist()
						}else{
							this.loading = false
						}
					}).catch(() => {
						this.loading = false
					})
				}
			},
			getsubjectslist() {
				subjectslist({
					pageNum: 1,
					pageSize: 999999
				}).then((res) => {
					this.subjectList = res.data.list
				})
			},
			gettenantslist() {
				tenantslist({
					pageNum: 1,
					pageSize: 999999
				}).then((res) => {
					this.tenantsList = res.data.list
				})
			},
			getpresseslist() {
				presseslist({
					pageNum: 1,
					pageSize: 999999
				}).then((res) => {
					this.pressesList = res.data.list
				})
			},
			gradechange() {
				this.addjson.classroomIds = []
				if (this.addjson.gradeId != null) {
					this.getclasslist(this.addjson.gradeId)
				} else {
					this.classList = null
				}
			},
			getclasslist(id) { //根据年级ID查询班级列表
				classroomslist({
					pageNum: 1,
					pageSize: 999999,
					gradeId: id
				}).then((res) => {
					this.classList = res.data.list
				})
			},
			getgradeslist() {
				gradeslist({
					pageNum: 1,
					pageSize: 999999
				}).then((res) => {
					this.gradeList = res.data.list
				})
			},
			getlist() { //获取列表
				this.allloading = true
				teacherlist({
					pageNum: this.pageNum,
					pageSize: this.pageSize
				}).then((res) => {
					this.list = res.data.list
					this.pages = res.data.pages
					this.allloading = false
				})
			},
			pagechange(index) {
				this.pageNum = index
				this.getlist()
			}
		}
	}
</script>

<style scoped>

</style>
